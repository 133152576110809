import React from 'react';

const TextBox = ({label, value, onChange}) => {
  return (
    <div className="uk-margin">
      <label htmlFor="key" className="uk-form-label">{label}</label>
      <div className="uk-form-controls">
        <input
          type="text"
          name={label}
          value={value}
          onChange={onChange}
          className="uk-input uk-form-small uk-form-width-medium"
          autoFocus
        />
      </div>
    </div>
  );
};

export default TextBox;
