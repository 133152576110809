import React from 'react';

const CheckBox = ({label, checked, onChange}) => (
  <div className="uk-margin">
    <div className="uk-form-controls">
      <input
        type="checkbox"
        id="save_check"
        name="save_check"
        checked={checked}
        onChange={onChange}
        className="uk-checkbox"
      />
      <label htmlFor="save_check" > {label}</label>
    </div>
  </div>
);

export default CheckBox;
