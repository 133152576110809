import React, { useState, useEffect } from 'react';
import SubmitButton from 'components/atoms/SubmitButton';
import CheckBox from 'components/atoms/CheckBox';
import TextBox from 'components/atoms/TextBox';
import PasswordBox from 'components/atoms/PasswordBox';
import MfaInput from 'components/atoms/MfaInput';
import ErrorMessage from 'components/atoms/ErrorMessage';

const AwsConnectionForm = ({message, onSubmit, label}) => {
  const STORAGE_ITEM_NAME = 'ConnectionInfo';

  const [awsAccessKeyId, setAwsAccessKeyId] = useState('');
  const [awsSecretAccessKey, setAwsSecretAccessKey] = useState('');
  const [mfaSerialNumber, setMfaSerialNumber] = useState('');
  const [mfaCode, setMfaCode] = useState('');
  const [saving, setSavingStatus] = useState(false);
  const [roleArn, setRoleArn] = useState('');

  const saveParameters = () => {
    localStorage.setItem(
      STORAGE_ITEM_NAME,
      JSON.stringify({
        awsAccessKeyId,
        awsSecretAccessKey,
        roleArn,
        mfaSerialNumber,
        saving,
      })
    );
  }

  const clearParameters = () => {
    localStorage.removeItem(STORAGE_ITEM_NAME);
  }

  const loadParameters = () => {
    const savedString = localStorage.getItem(STORAGE_ITEM_NAME);
    if (!savedString) {
      return;
    }

    setSavingStatus(true);
    try{
      const saved = JSON.parse(savedString);

      if (saved.awsSecretAccessKey) {
        setAwsAccessKeyId(saved.awsAccessKeyId);
      }
      if (saved.awsSecretAccessKey) {
        setAwsSecretAccessKey(saved.awsSecretAccessKey);
      }
      if (saved.roleArn) {
        setRoleArn(saved.roleArn);
      }
      if (saved.mfaSerialNumber) {
        setMfaSerialNumber(saved.mfaSerialNumber);
      }
    } catch(err) {
      console.error(`JSON parse error: ${err.message}`);
    }
    return;
  }

  useEffect(()=>{
    loadParameters();
  }, []);

  return (
    <form onSubmit={event => {
      if (saving) {
        saveParameters();
      } else {
        clearParameters();
      }
      onSubmit(
        {
          roleArn,
          awsAccessKeyId,
          awsSecretAccessKey,
          mfaSerialNumber,
          mfaCode
        }
      );
      return event.preventDefault();
    }}>
      <fieldset className="uk-fieldset">

        <legend className="uk-legend">{label}</legend>

        <TextBox
          label="aws_access_key_id"
          value={awsAccessKeyId}
          onChange={event => setAwsAccessKeyId(event.target.value)}
        />
        <PasswordBox
          label="aws_secret_access_key"
          value={awsSecretAccessKey}
          onChange={event => setAwsSecretAccessKey(event.target.value)}
        />
        <TextBox
          label="MFA Device"
          value={mfaSerialNumber}
          onChange={event => setMfaSerialNumber(event.target.value)}
        />
        <TextBox
          label="Role Arn"
          value={roleArn}
          onChange={event => setRoleArn(event.target.value)}
        />
        <MfaInput
          label="MFA Code"
          value={mfaCode}
          onChange={event => setMfaCode(event.target.value)}
        />
        <CheckBox
          label="save to browser"
          checked={saving}
          onChange={event => setSavingStatus(event.target.checked)}
        />
        <SubmitButton label="connect"/>
        <ErrorMessage message={message}/>
      </fieldset>
    </form>
  );
};

export default AwsConnectionForm;
