import AWS from 'aws-sdk';

export default class StagingManager {
  constructor(config, onTokenExpired) {
    this.config = config
    this.onTokenExpired = onTokenExpired;
  }

  async getAsync() {
    try {
      AWS.config = this.config;

      const lambda = new AWS.Lambda();
      const record = await lambda.invoke({
        FunctionName: 'GetEnvironments',
      }).promise();

      const response = JSON.parse(record.Payload);

      return JSON.parse(response.body)['data'];
    } catch(error) {
      if (error.code === 'ExpiredTokenException') {
        this.onTokenExpired();

        return [];
      }

      throw error;
    }
  }

  async createAmiAsync(name, type, params) {
    try {
      AWS.config = this.config;

      const lambda = new AWS.Lambda();

      const body = {
        name: name,
        type: type,
        params: params,
      };
      const record = await lambda.invoke({
        FunctionName: 'CreateEnvironment',
        Payload: JSON.stringify({
          'body': JSON.stringify(body),
        }),
      }).promise();

      const response = JSON.parse(record.Payload);

      const data = JSON.parse(response.body);

      if (data.result !== 'success') {
        throw new Error(data.message);
      }
    } catch (error) {
      if (error.code === 'ExpiredTokenException') {
        this.onTokenExpired();
      }
      console.error(error);

      throw new Error(error.message);
    }
  }

  async updateAsync(name, body) {
    try {
      AWS.config = this.config;

      const lambda = new AWS.Lambda();

      const record = await lambda.invoke({
        FunctionName: 'UpdateEnvironment',
        Payload: JSON.stringify({
          'pathParameters': {'name': name},
          'body': JSON.stringify(body),
        }),
      }).promise();

      const response = JSON.parse(record.Payload);

      const data = JSON.parse(response.body);

      if (data.result !== 'success') {
        throw new Error(data.message);
      }
    } catch (error) {
      if (error.code === 'ExpiredTokenException') {
        this.onTokenExpired();
      }
      console.error(error);

      throw new Error(error.message);
    }
  }

  async deleteAsync(name) {
    try {
      AWS.config = this.config;

      const lambda = new AWS.Lambda();
      const record = await lambda.invoke({
        FunctionName: 'DropEnvironment',
        Payload: JSON.stringify({
          'pathParameters': {'name': name},
          'body': null,
        }),
      }).promise();

      const response = JSON.parse(record.Payload);

      const data = JSON.parse(response.body);

      if (data.result !== 'success') {
        throw new Error(data.message);
      }
    } catch (error) {
      if (error.code === 'ExpiredTokenException') {
        this.onTokenExpired();
      }
      console.error(error);

      throw new Error(error.message);
    }
  }
}
