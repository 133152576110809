import React, { useState } from 'react';
import Modal from 'react-modal';
import AwsConnectionForm from 'components/molecules/AwsConnectionForm';
import AwsConnector from 'utils/AwsConnector';
import AWS from 'aws-sdk';

const SessionModal = ({target, session, setSession, children}) => {
  const [message, setMessage] = useState('');
  const awsConnector = new AwsConnector();

  const regions = {
    'staging': 'ap-southeast-1',
    'operation': 'ap-northeast-1',
  }

  const onSubmit = async(target, params) => {
    try {
      const session = await awsConnector.connect(
        params.roleArn,
        params.awsAccessKeyId,
        params.awsSecretAccessKey,
        params.mfaSerialNumber,
        params.mfaCode
      );

      setSession(session)
    } catch(err) {
      setMessage(err.message)
    }
  };

  if (!children) {
    return null;
  }

  if (!session || !session.Credentials) {
    return (
      <Modal isOpen={true}>
        <AwsConnectionForm
          message={message}
          onSubmit={params=>onSubmit(target, params)}
          label={`Connect to ${target}`}
        />
      </Modal>
    );
  }

  return React.cloneElement(children, {
    awsConfig: new AWS.Config({
      region: regions[target],
      credentials: new AWS.Credentials(
        session.Credentials.AccessKeyId,
        session.Credentials.SecretAccessKey,
        session.Credentials.SessionToken,
      ),
    }),
    onTokenExpired: () => setSession(target, null),
  });
};

export default SessionModal;
