import React from 'react';

const SubmitButton = ({label='submit', disabled=false}) => {
  return (
    <div className="uk-margin">
      <input
        type="submit"
        className="uk-button uk-button-default uk-button-primary"
        value={label}
        disabled={disabled}
      />
    </div>
  );
};

export default SubmitButton;
