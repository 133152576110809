import React from 'react';

const SelectBox = ({label, value, onChange, children}) => {
  return (
    <div className="uk-margin">
      <label htmlFor="key" className="uk-form-label">{label}</label>
      <div className="uk-form-controls">
        <select className="uk-select" value={value} onChange={onChange}>
          {
            children
          }
        </select>
      </div>
    </div>
  );
};

export default SelectBox;
