import React from 'react';
import { Switch, Route } from 'react-router-dom';
import TestPipelineView from './TestPipelineView';
import StagingPipelineView from './StagingPipelineView';
import StagingEnvironmentView from './StagingEnvironmentView';

const Main = () => {
  return (
    <div className="uk-section section" style={{textAlign: "left"}}>
      <div className="uk-container uk-container-expand">
        <Switch>
          <Route path='/test-pipeline' component={TestPipelineView}/>
          <Route path='/staging-pipeline' component={StagingPipelineView}/>
          <Route path='/staging-environment' component={StagingEnvironmentView}/>
        </Switch>
      </div>
    </div>
  );
};

//デフォルトで呼び出される関数
export default Main;
