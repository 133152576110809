import React, { useState, useEffect } from 'react';
import ErrorMessage from 'components/atoms/ErrorMessage';
import SubmitButton from 'components/atoms/SubmitButton';

const TextArea = ({label, value, onChange, rows=20}) => {
  return (
    <div className="uk-margin">
      <label htmlFor="key" className="uk-form-label">{label}</label>
      <div className="uk-form-controls">
        <textarea
          type="text"
          name={label}
          value={value}
          onChange={onChange}
          className="uk-textarea"
          rows={rows}
          autoFocus
        />
      </div>
    </div>
  );
};

const JsonParameterBlock = ({onChange}) => {
  const [text, setText] = useState('');

  useEffect(
    ()=>{
      try {
        const param = JSON.parse(text);
        onChange(param);
      } catch(error) {
        onChange(null);
      }
    },
    [text]
  );

  return (
    <TextArea label="parameter (json)"
      value={text}
      onChange={event => setText(event.target.value)}
    />
  );
}

export default ({name, isProcessing, errorMessage, onUpdate}) => {
  const [parameter, setParameter] = useState({});

  return (
    <form onSubmit={(event)=>{
      onUpdate(parameter);
      return event.preventDefault();
    }}>
      <fieldset className="uk-fieldset">
        <legend className="uk-legend">Edit Environment "{name}"</legend>

        <JsonParameterBlock onChange={setParameter}/>

        <SubmitButton label="Update" disabled={isProcessing}/>
        <ErrorMessage message={errorMessage}/>
      </fieldset>
    </form>
  );
};
