const SET_SESSION = 'SET_SESSION';

export const createSetSessionAction = (name, value) => {
  return {type: SET_SESSION, name, value};
};

export const reducer = (state = {}, action) => {
  switch(action.type) {
    case SET_SESSION:
      const nextState = {...state};
      nextState[action.name] = action.value;

      return nextState;
    default:
      return state;
  }
};
