import React from 'react';
import StackStatus from 'components/atoms/StackStatus';
import DeleteButton from 'components/atoms/DeleteButton';
import EditButton from 'components/atoms/EditButton';

const StagingEnvironmentRecord = ({stagingEnvironment, onEdit, onDelete}) => {
  const value = stagingEnvironment['pipeline_stack']['status'];
  const name = stagingEnvironment['name'];
  return (
    <tr key={name}>
      <td>{name}</td>
      <td><StackStatus value={value}/></td>
      <td><EditButton onClick={()=>onEdit(name)}/></td>
      <td><DeleteButton onClick={()=>onDelete(name)}/></td>
    </tr>
  );
};

export default StagingEnvironmentRecord;
