import React from 'react';
import Modal from 'react-modal';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from 'stores/reducer';
import * as storage from 'redux-storage';
import createEngine from 'redux-storage-engine-localstorage';
import Header from 'components/templates/Header';
import Main from 'components/templates/Main';
import './App.css';

Modal.setAppElement('#root');

const engine = createEngine('my-save-key');
const storeEnhancer = applyMiddleware(storage.createMiddleware(engine));
const createStoreWithMiddleWare = storeEnhancer(createStore);

const wrappedReducer = storage.reducer(rootReducer);
const store = createStoreWithMiddleWare(wrappedReducer);
const load = storage.createLoader(engine);
load(store);

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="App">
          <Route path='*' component={Header}/>
          <Main />
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
