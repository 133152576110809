import React, { useState, useEffect } from 'react';
import ErrorMessage from 'components/atoms/ErrorMessage';
import SelectBox from 'components/atoms/SelectBox';
import TextBox from 'components/atoms/TextBox';
import SubmitButton from 'components/atoms/SubmitButton';

const AmiParameters = ({onChange}) => {
  const [amiBuilderRevision, setAmiBuilderRevision] = useState('master');
  const [ansibleRevision, setAnsibleRevision] = useState('master');

  useEffect(
    ()=>onChange({
      "ami_build_env": {
        "repository": {
          "name": 'ebis_cicd_ami_builder',
          "revision": amiBuilderRevision,
        },
        "template_path": "files/ami-build-env.yml",
        "stack_name": `cicd-substack-${amiBuilderRevision.replace('/', '-').replace('_', '-')}`,
      },
      "ansible": {
        "repository": {
          "name": "mpf_ansible",
          "revision": ansibleRevision
        },
        "host_stage": "stg"
      }
    }),
    [amiBuilderRevision, ansibleRevision]
  );

  return (
    <>
      <TextBox label="ami revision"
        value={amiBuilderRevision}
        onChange={event => setAmiBuilderRevision(event.target.value)}
      />
      <TextBox label="ansible revision"
        value={ansibleRevision}
        onChange={event => setAnsibleRevision(event.target.value)}
      />
    </>
  );
};

const TextArea = ({label, value, onChange, rows=20}) => {
  return (
    <div className="uk-margin">
      <label htmlFor="key" className="uk-form-label">{label}</label>
      <div className="uk-form-controls">
        <textarea
          type="text"
          name={label}
          value={value}
          onChange={onChange}
          className="uk-textarea"
          rows={rows}
          autoFocus
        />
      </div>
    </div>
  );
};

const JsonParameters = ({onChange}) => {
  const [text, setText] = useState('');

  useEffect(
    ()=>{
      try {
        const param = JSON.parse(text);
        onChange(param);
      } catch(error) {
        onChange(null);
      }
    },
    [text]
  );

  return (
    <TextArea label="parameter (json)"
      value={text}
      onChange={event => setText(event.target.value)}
    />
  );
}

const FormInputBlock = ({onChange}) => {
  const [name, setName] = useState('');
  const [selectedType, setSelectedType] = useState('ami');
  const [parameters, setParameters] = useState({});

  const typeInfo = {
    ami: {label: 'AMI Builder', component: (<AmiParameters onChange={setParameters}/>)},
    sam: {label: 'sam'},
    cfn: {label: 'cfn'},
    serverless: {label: 'serverless'},
  }

  useEffect(()=>{
    onChange({
      name,
      type: selectedType,
      params: parameters,
    });
  },[name, selectedType, parameters]);

  return (
    <>
      <TextBox label="Name" value={name} onChange={event => setName(event.target.value)}/>
      <SelectBox
        label="type"
        value={selectedType}
        onChange={(event)=>setSelectedType(event.target.value)}
      >
        <option value="ami">AMI Builder</option>
        <option value="sam">sam</option>
        <option value="cfn">Cloudformation</option>
        <option value="serverless">Serverless</option>
      </SelectBox>
      {
        typeInfo[selectedType].component || (<JsonParameters onChange={param=>setParameters(param)}/>)
      }
    </>
  );
}

const JsonParameterBlock = ({onChange}) => {
  const [text, setText] = useState('');

  useEffect(
    ()=>{
      try {
        const param = JSON.parse(text);
        onChange(param);
      } catch(error) {
        onChange(null);
      }
    },
    [text]
  );

  return (
    <TextArea label="parameter (json)"
      value={text}
      onChange={event => setText(event.target.value)}
    />
  );
}

const CreateEnvironmentForm = ({stagingManager, onCreate}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [parameter, setParameter] = useState({});
  const [mode, setMode] = useState('json');
  const [isComplete, setComplete] = useState(false);

  const onFormSubmit = async() => {
    try{
      await stagingManager.createAmiAsync(
        parameter.name,
        parameter.type,
        parameter.params
      );
      onCreate();
    } catch(error) {
      setErrorMessage(error.message);
    }
  };

  useEffect(
    ()=>{
      if (!parameter || !parameter.name || parameter.name.length === 0) {
        setComplete(false);
        return;
      }

      setComplete(true);
    },
    [parameter]
  );

  return (
    <form onSubmit={(event)=>{
      onFormSubmit();
      return event.preventDefault();
    }}>
      <fieldset className="uk-fieldset">
        <legend className="uk-legend">Create Environment</legend>

        {
          ['json', 'form'].map(value=>(
            <>
              <input
                type="radio"
                id={value}
                name="mode"
                value={value}
                onChange={event=>{
                  setMode(event.currentTarget.value);
                }}
              />
              <label htmlFor={value}>{value}</label><br/>
            </>
          ))
        }

        {
          mode === 'json' ? (
            <JsonParameterBlock onChange={setParameter}/>
          ) : (
            <FormInputBlock onChange={setParameter}/>
          )
        }

        <SubmitButton disabled={!isComplete}/>
        <ErrorMessage message={errorMessage}/>
      </fieldset>
    </form>
  );
};

export default CreateEnvironmentForm;
