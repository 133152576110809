import React from 'react';
import ErrorMessage from 'components/atoms/ErrorMessage';
import SubmitButton from 'components/atoms/SubmitButton';

export default ({name, isProcessing, errorMessage, onSubmit}) => {
  return (
    <form onSubmit={(event)=>{
      onSubmit(name);
      return event.preventDefault();
    }}>
      <fieldset className="uk-fieldset">
        <legend className="uk-legend">Delete Environment "{name}"</legend>

        <SubmitButton disabled={isProcessing}/>
        <ErrorMessage message={errorMessage}/>
      </fieldset>
    </form>
  );
};
