import React, { useState, useEffect } from 'react';
import TestPipelineTable from './TestPipelineTable';
import TestPipelineManager from 'utils/TestPipelineManager';

const TestPipelineArticle = ({awsConfig, onTokenExpired}) => {
  const [isLoaded, setLoaded] = useState(false);
  const [pipelines, setPipelines] = useState([]);

  const testPipelineManager = new TestPipelineManager(
    awsConfig,
    onTokenExpired
  );

  const load = async() => {
    if (!testPipelineManager) { return; }
    setPipelines(await testPipelineManager.getPipelines());
    setLoaded(true);
  };

  useEffect(()=>{
    if (isLoaded) { return; }
    load();
  }, [isLoaded, awsConfig]);

  return (
    <div className="uk-card uk-card-default uk-card-body">
      <h3 className="uk-card-title">TEST PIPELINE</h3>
      <TestPipelineTable pipelines={pipelines} />
    </div>
  )
};

export default TestPipelineArticle;
