import AWS from 'aws-sdk';

export default class AwsConnector {
  async connect(roleArn, awsAccessKeyId, awsSecretAccessKey, serialNumber, tokenCode) {
    const config = new AWS.Config({
      accessKeyId: awsAccessKeyId,
      secretAccessKey: awsSecretAccessKey,
    });

    AWS.config = config;

    const sts = new AWS.STS();
    const stagingRoleSession = await sts.assumeRole({
      RoleArn: roleArn,
      RoleSessionName: 'stagingSession',
      SerialNumber: serialNumber,
      TokenCode: tokenCode,
    }).promise();

    return stagingRoleSession;
  }
}
