import React from 'react';
import NaviBar from 'components/organisms/NaviBar';

const Header = (props) => {
  const { match: { params } } = props;

  return (
    <NaviBar path={params[0]}/>
  );
};

export default Header;
