import React from 'react';


const StagingPipelineTable = ({pipelines}) => {
  return (
    <table className="uk-table uk-table-divider">
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {
          pipelines.map((pipeline)=>{
            return (
              <tr key={pipeline.pipelineName}>
                <td><a href={pipeline.getUrl()} target="_blank" rel="noopener">{pipeline.getName()}</a></td>
                <td>{pipeline.getType()}</td>
                <td>{pipeline.getStatus()}</td>
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
}

export default StagingPipelineTable;
