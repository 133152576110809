import React from 'react';
import StagingEnvironmentRecord from 'components/molecules/StagingEnvironmentRecord';

const StagingEnvironmentTable = ({records, onEdit, onDelete}) => {
  return (
    <table className="uk-table uk-table-divider">
      <thead>
        <tr>
          <th>Name</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          records.map((stagingEnvironment, idx) => (
            <StagingEnvironmentRecord
              key={idx}
              stagingEnvironment={stagingEnvironment}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))
        }
      </tbody>
    </table>
  );
}

export default StagingEnvironmentTable;
