import React from 'react';

const CompleteStatus = ({value}) => {
  return <div className="complete_status"><span uk-icon="check"></span> {value}</div>
};

const DeleteStatus = ({value}) => {
  return <div className="failed_status"><span uk-icon="warning"></span> {value}</div>
};

const StackStatus = ({value}) => {
  if (value === 'DELETE_FAILED') {
    return <DeleteStatus value={value}/>
  } else {
    return <CompleteStatus value={value}/>
  }
};

export default StackStatus;
