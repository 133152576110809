import AWS from 'aws-sdk';

class Pipeline {
  constructor(pipelineName, tags, pipelineExecutions) {
    this.pipelineName = pipelineName;
    this.tags = tags;
    this.pipelineExecutions = pipelineExecutions;
  }

  getName() {
    if (this.tags['Name']) {
      return this.tags['Name'];
    }

    const matched = this.pipelineName.match(/environment-(.*)-[ReleaseBranchPipeline,Pipeline].*/)
    if (matched) {
      return matched[1];
    }

    return this.pipelineName;
  }

  getType() {
    return this.tags['TemplateType'] || 'unknown';
  }

  getStatus() {
    const e = this.pipelineExecutions[0];

    return e.status;
  }

  getUrl() {
    return `https://ap-southeast-1.console.aws.amazon.com/codesuite/codepipeline/pipelines/${this.pipelineName}/view?region=ap-southeast-1`;
  }

  static async load(codePipeline, pipelineName) {
    let tags = {};
    const p = await codePipeline.getPipeline({name:pipelineName}).promise();
    const tagResponse = await codePipeline.listTagsForResource({resourceArn: p.metadata.pipelineArn}).promise();
    tagResponse.tags.map(tag=>{tags[tag.key] = tag.value});

    const executions = await codePipeline.listPipelineExecutions({pipelineName: pipelineName}).promise();

    const pipelineExecutions = executions.pipelineExecutionSummaries.map(pipelineExecutionSummary=>
      new PipelineExecution(pipelineExecutionSummary.status, pipelineExecutionSummary.lastUpdateTime)
    );

    return new Pipeline(pipelineName, tags, pipelineExecutions);
  }
}

class PipelineExecution {
  constructor(status, lastUpdateTime) {
    this.status = status;
    this.lastUpdateTime = lastUpdateTime;
  }
}

class StagingPipelineManager {
  constructor(config, onTokenExpired) {
    this.config = config;
  }

  async getPipelines() {
    AWS.config = this.config
    const codePipeline = new AWS.CodePipeline();
    // const resourceGroupsTaggingApi = new AWS.ResourceGroupsTaggingAPI({apiVersion: '2017-01-26'});

    const response = await codePipeline.listPipelines().promise();
    const pipelineNames = response.pipelines.map(pipeline => {
      return pipeline.name;
    });

    // const values = resourceGroupsTaggingApi.getTagValues({Key: 'TemplateType'}).promise();
    // console.log(values);
    return await Promise.all(pipelineNames.map(pipelineName=>Pipeline.load(codePipeline, pipelineName)));
  }
}

export default StagingPipelineManager;
