import { connect } from 'react-redux';
import { createSetSessionAction } from 'stores/session';
import SessionModal from 'components/organisms/SessionModal';

const mapStateToProps = (state, {target}) => ({
  session: state.session[target]
});

const mapDispatchToProps = (dispatch, {target}) => ({
  setSession: (value) => dispatch(createSetSessionAction(target, value)),
  onTokenExpired: () => dispatch(createSetSessionAction(target, null)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionModal);
