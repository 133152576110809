import React, { useState, useEffect } from 'react';
import StagingPipelineManager from 'utils/StagingPipelineManager';
import StagingPipelineTable from './StagingPipelineTable';

const StagingPipelineArticle = ({awsConfig, onTokenExpired}) => {
  const [isLoaded, setLoaded] = useState(false);
  const [pipelines, setPipelines] = useState([]);

  const load = async() => {
    const stagingPipelineManager = new StagingPipelineManager(
      awsConfig,
      onTokenExpired
    );
    setPipelines(await stagingPipelineManager.getPipelines());
    setLoaded(true);
  };

  useEffect(()=>{
    if (isLoaded) { return; }
    load();
  }, [isLoaded, awsConfig]);

  return (
    <div className="uk-card uk-card-default uk-card-body">
      <h3 className="uk-card-title">STAGING PIPELINE</h3>
      <StagingPipelineTable pipelines={pipelines}/>
    </div>
  );
}

export default StagingPipelineArticle;
