import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import CreateButton from 'components/atoms/CreateButton';
import StagingManager from 'utils/StagingManager';
import StagingEnvironmentTable from './StagingEnvironmentTable';
import CreateEnvironmentForm from 'components/molecules/CreateEnvironmentForm';
import DeleteEnvironmentForm from 'components/molecules/DeleteEnvironmentForm';
import EditEnvironmentForm from 'components/molecules/EditEnvironmentForm';


const StagingEnvironmentArticle = ({awsConfig, onTokenExpired}) => {
  const [isModalOpening, setModalOpening] = useState(false);

  const [edittingName, setEdittingName] = useState('');
  const [updateErrorMessage, setUpdateErrorMessage] = useState('');
  const [isEditProcessing, setEditProcessing] = useState(false);
  const [isEditModalOpening, setEditModalOpening] = useState(false);

  const [deletingName, setDeletingName] = useState('');
  const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
  const [isDeleteProcessing, setDeleteProcessing] = useState(false);
  const [isDeleteModalOpening, setDeleteModalOpening] = useState(false);

  const [records, setRecords] = useState([]);
  const [isLoaded, setLoaded] = useState(false);

  const stagingManager = new StagingManager(
    awsConfig,
    onTokenExpired
  );

  const load = async() => {
    if (!stagingManager) { return; }
    try {
      setRecords(await stagingManager.getAsync());
      setLoaded(true);
    } catch(err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (isLoaded) { return; }

    load();
  }, [isLoaded, awsConfig]);

  return (
    <div className="uk-card uk-card-default uk-card-body">
      <Modal isOpen={isModalOpening} onRequestClose={()=>{setModalOpening(false)}}>
        <CreateEnvironmentForm stagingManager={stagingManager} onCreate={()=>{setModalOpening(false)}}/>
      </Modal>
      <Modal isOpen={isEditModalOpening} onRequestClose={()=>{setEditModalOpening(false)}}>
        <EditEnvironmentForm
          name={edittingName}
          isProcessing={isEditProcessing}
          errorMessage={updateErrorMessage}
          onUpdate={async(parameter)=>{
            try {
              setEditProcessing(true);
              await stagingManager.updateAsync(edittingName, parameter);
              setEditModalOpening(false);
              setLoaded(false);
            } catch(err) {
              setUpdateErrorMessage(err.message);
            } finally {
              setEditProcessing(false);
            }
          }}
        />
      </Modal>
      <Modal isOpen={isDeleteModalOpening} onRequestClose={()=>{setDeleteModalOpening(false)}}>
        <DeleteEnvironmentForm
          name={deletingName}
          isProcessing={isDeleteProcessing}
          errorMessage={deleteErrorMessage}
          onSubmit={async(name)=>{
            try {
              setDeleteProcessing(true);
              await stagingManager.deleteAsync(name);
              setDeleteModalOpening(false);
              setLoaded(false);
            } catch(err) {
              setDeleteErrorMessage(err.message);
            } finally {
              setDeleteProcessing(false);
            }
          }}
        />
      </Modal>
      <h3 className="uk-card-title">
        STAGING ENVIRONMENTS <CreateButton onClick={()=>setModalOpening(true)}/>
      </h3>
      <StagingEnvironmentTable
        records={records}
        onEdit={(name)=>{
          setEdittingName(name);
          setEditModalOpening(true);
        }}
        onDelete={(name)=>{
          setDeletingName(name);
          setDeleteModalOpening(true);
        }}
      />
    </div>
  );
};

export default StagingEnvironmentArticle;
