import React from 'react';

const MfaInput = ({label, value, onChange}) => {
  return (
    <div className="uk-margin">
      <label htmlFor="mfa_code" className="uk-form-label">{label}</label>
      <div className="uk-form-controls">
        <input
          type="text"
          id="mfa_code"
          name="mfa_code"
          maxLength='6'
          value={value}
          onChange={onChange}
          className="uk-input uk-form-small uk-form-width-medium"
          autoComplete='off'
        />
      </div>
    </div>
  );
};

export default MfaInput;
