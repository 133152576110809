import React from 'react';
import { Link } from 'react-router-dom';
import ClearSession from 'containers/ClearSession';

const NaviItem = ({label, path, currentPath}) => (
  <li className={currentPath === path ? "uk-active" : ""}>
    <Link to={path}>{label}</Link>
  </li>
);

const NaviBar = ({path}) => {
  return (
    <div className="uk-container uk-container-expand uk-background-primary">
      <nav className="uk-navbar-container uk-navbar uk-navbar-transparent uk-light">
        <div className="uk-navbar-left">
          <a href="/" className="uk-logo">CICD client</a>
        </div>
        <div className="uk-navbar-right">
          <ul className="uk-navbar-nav">
            <NaviItem label="TEST PIPELINE" path="/test-pipeline" currentPath={path}/>
            <NaviItem label="STAGING PIPELINE" path="/staging-pipeline" currentPath={path}/>
            <NaviItem label="STAGING ENVIRONMENT" path="/staging-environment" currentPath={path}/>
          </ul>
          <div class="uk-navbar-item uk-visible@m">
            <ClearSession/>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NaviBar;
