import React from 'react';

const CreateButton = ({onClick}) => {
  return (
    <span>
      <button
        onClick={onClick}
        className="uk-icon-button create_environment_button"
        uk-tooltip="Create New Environment"
      >
        <span uk-icon="plus"></span>Create
      </button>
    </span>
  );
};

export default CreateButton;
