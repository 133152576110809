import AWS from 'aws-sdk';

class TestPipeline {
  constructor(pipeline, pipelineExecutions) {
    this.pipeline = pipeline;
    this.pipelineExecutions = pipelineExecutions;
  }

  getName() {
    if (this.pipeline.tags['Name']) {
      return this.pipeline.tags['Name'];
    }

    const matched = this.pipeline.pipelineName.match(/environment-(.*)-[ReleaseBranchPipeline,Pipeline].*/)
    if (matched) {
      return matched[1];
    }

    return this.pipeline.pipelineName;
  }

  getStatus() {
    const e = this.pipelineExecutions[0];

    return e ? e.status : '';
  }

  getRepositoryName() {
    return this.pipeline.tags['TargetRepository'];
  }
  getBranchName() {
    return this.pipeline.tags['TargetBranch'];
  }

  getUrl() {
    return `https://ap-northeast-1.console.aws.amazon.com/codesuite/codepipeline/pipelines/${this.pipeline.pipelineName}/view?region=ap-northeast-1`;
  }
}

class Pipeline {
  constructor(pipelineName, tags) {
    this.pipelineName = pipelineName;
    this.tags = tags;
  }

  static async loadTags(codePipeline, pipelineArn) {
    let tags = {};
    const tagResponse = await codePipeline.listTagsForResource({resourceArn: pipelineArn}).promise();
    tagResponse.tags.map(tag=>{tags[tag.key] = tag.value});

    return tags;
  }

  static async load(codePipeline, pipelineName) {
    const pipeline = await codePipeline.getPipeline({name: pipelineName}).promise();
    const tags = await Pipeline.loadTags(codePipeline, pipeline.metadata.pipelineArn);

    return new Pipeline(
      pipelineName,
      tags,
    );
  }
}

class PipelineExecution {
  constructor(status, lastUpdateTime) {
    this.status = status;
    this.lastUpdateTime = lastUpdateTime;
  }

  static async load(codePipeline, pipelineName) {
    const executions = await codePipeline.listPipelineExecutions({pipelineName: pipelineName}).promise();
    const pipelineExecutions = executions.pipelineExecutionSummaries.map(pipelineExecutionSummary=>
      new PipelineExecution(pipelineExecutionSummary.status, pipelineExecutionSummary.lastUpdateTime)
    );

    return pipelineExecutions;
  }
}

class TestPipelineManager {
  constructor(config, onTokenExpired) {
    this.config = config;
    this.onTokenExpired = onTokenExpired;
  }

  async getPipelines() {
    try {
      AWS.config = this.config
      const codePipeline = new AWS.CodePipeline();

      const response = await codePipeline.listPipelines().promise();
      const pipelineNames = response.pipelines.map(pipeline => {
        return pipeline.name;
      });

      // FIXME: Too much pipepile to fetch all
      const awsPipelines = await Promise.all(pipelineNames.slice(0, 20).map(pipelineName=>Pipeline.load(codePipeline, pipelineName)));
      const filteredPipelines = awsPipelines.filter(pipeline => pipeline.tags['Owner'] ===  "ebis_release_pipelines");

      return await Promise.all(filteredPipelines.map(pipeline => this.createPipeline(codePipeline, pipeline)));
    } catch(error) {
      if (error.code === 'ExpiredTokenException') {
        this.onTokenExpired();
      }
      throw error;
    }
  }

  async createPipeline(codePipeline, pipeline) {
    return new TestPipeline(pipeline, await PipelineExecution.load(codePipeline, pipeline.pipelineName));
  }
}

export default TestPipelineManager;
