import { connect } from 'react-redux';
import { createSetSessionAction } from 'stores/session';
import SessionClearButton from 'components/atoms/ClearSessionButton';

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  clearSession: () => dispatch(createSetSessionAction('staging', null)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionClearButton);
