import React from 'react';

const DeleteButton = ({onClick}) => {
  return (
    <button onClick={onClick} className="uk-button uk-button-link uk-button-small" uk-tooltip="Delete Environment">
      <span style={{color: "#2187F0"}} uk-icon="trash" className="uk-text-small"></span> Delete
    </button>
  );
};

export default DeleteButton;
